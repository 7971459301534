<!--
 * @Description: 海报列表
 * @Author: xiawenlong
 * @Date: 2021-10-14 08:58:55
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-03-24 08:48:20
-->
<template>
  <div class="poster">
    <div class="header">
      <div class="title">海报列表</div>
      <div class="btns">
        <el-button @click="toCreate">制作海报</el-button>
        <el-button type="primary" class="download" @click="downloadQr">下载二维码</el-button>
        <!-- <el-button type="danger">删除</el-button> -->
      </div>
    </div>
    <div v-if="posterList.length == 0" class="empty">
      <img src="@/assets/teacher/empty.png" alt="" />
      <p>暂无海报</p>
    </div>
    <div v-else class="poster-list">
      <div v-for="item in posterList" :key="item.id" class="poster-item">
        <div class="cover" @click="toPreview(item)">
          <img :src="item.posterPic" alt="" />
        </div>
        <div class="content">
          <div class="name">
            <template v-if="!item.edit">
              <span>{{ item.posterName }}</span>
              <img
                src="@/assets/teacher/edit_icon_black.png"
                class="editName"
                alt=""
                @click="item.edit = true"
              />
            </template>
            <el-input
              v-else
              v-model="item.posterName"
              v-clickoutside="
                () => {
                  posterNameClickoutside(item)
                }
              "
              maxlength="64"
              size="mini"
              @keyup.enter.native="posterNameClickoutside(item)"
            ></el-input>
          </div>
          <div class="tool">
            <div class="time">{{ item.createDate }}</div>
            <div class="btns">
              <img
                src="@/assets/teacher/poster_download_icon.png"
                alt=""
                class="download"
                @click="toDownload(item)"
              />
              <img
                src="@/assets/teacher/preview_icon.png"
                alt=""
                class="preview"
                @click="toPreview(item)"
              />
              <img
                src="@/assets/teacher/poster_edit_icon.png"
                alt=""
                class="edit"
                @click="toEdit(item)"
              />
              <img
                src="@/assets/teacher/poster_delete_icon.png"
                alt=""
                class="delete"
                @click="toDelete(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PosterPreview ref="preview" :is-download="true" />
  </div>
</template>
<script>
import {
  teacherPosterInviteCode,
  teacherPosterQuery,
  teacherPosterUpdate,
  teacherPosterDelete,
} from '@/api/teacher'
import Clickoutside from 'element-ui/src/utils/clickoutside'
import PosterPreview from './components/PosterPreview'
import to from 'await-to'
export default {
  name: 'Poster',
  directives: { Clickoutside },
  components: { PosterPreview },
  data() {
    return {
      posterList: [],
      qrCode: '',
    }
  },
  mounted() {
    this.getList()
    this.getQrCode()
  },
  methods: {
    async getList() {
      const { reserveId } = this.$route.query
      const [res, err] = await to(teacherPosterQuery({ reserveId }))
      if (err) return this.$message.warning(err.msg)
      this.posterList = res.data.map(item => ({
        ...item,
        edit: false,
      }))
    },
    toCreate() {
      const { reserveId } = this.$route.query
      this.$router.push(`/teacher/poster/add?reserveId=${reserveId}`)
    },
    toEdit({ id, contentText, templateLabel }) {
      const { reserveId } = this.$route.query
      localStorage.setItem('PosterContent', contentText)
      localStorage.setItem('activeTemplateLabel', templateLabel)
      this.$router.push(`/teacher/poster/${id}?reserveId=${reserveId}`)
    },
    toDownload({ posterPic }) {
      try {
        let alink = document.createElement('a')
        alink.href = posterPic
        alink.target = '_blank'
        alink.download = 'qrCode.png'
        alink.click()
      } catch (e) {
        console.log(e)
      }
    },
    toPreview({ posterPic }) {
      this.$refs.preview.open(posterPic)
    },
    toDelete({ id }) {
      this.$confirm('确定要删除所选海报吗?', '确定删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const [, err] = await to(teacherPosterDelete({ idList: [id] }))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('删除成功')
          this.getList()
        })
        .catch(() => {})
    },
    posterNameClickoutside(item) {
      if (!item.edit) return
      item.edit = false
      this.updateName(item)
    },
    async updateName({ id, posterName }) {
      const params = new FormData()
      params.append('id', id)
      params.append('posterName', posterName)
      const [, err] = await to(teacherPosterUpdate(params))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('修改成功')
    },
    // 获取二维码
    async getQrCode() {
      const { reserveId } = this.$route.query
      const [res, err] = await to(teacherPosterInviteCode({ reserveId }))
      if (err) return this.$message.warning(err.msg)
      this.qrCode = res.data.qrCode
    },
    // 保存并下载二维码
    async downloadQr() {
      try {
        let alink = document.createElement('a')
        alink.href = this.qrCode
        alink.download = 'qrCode.png'
        alink.click()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.poster {
  .header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #4d4e5d;
    }
  }
  .poster-list {
    display: flex;
    .poster-item {
      width: 258px;
      height: 336px;
      background: #ffffff;
      border-radius: 10px;
      margin-right: 25px;
      margin-bottom: 25px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        transform: scale(1.09);
        box-shadow: 2px 4px 17px 0px rgba(0, 0, 0, 0.28);
      }
      .cover {
        width: 258px;
        height: 268px;
        background: #dfdfdf;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 138px;
          height: 246px;
        }
      }
      .content {
        box-sizing: border-box;
        height: 68px;
        padding: 10px 14px 11px;
        .name {
          display: flex;
          align-items: center;
          line-height: 17px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 11px;
          > span {
            max-width: calc(100% - 20px);
            @extend %one-line-text;
          }
          ::v-deep.el-input {
            input {
              height: 18px;
              font-size: 14px;
              font-weight: 500;
              color: #333333;
              padding: 0;
            }
          }
          &:hover {
            .editName {
              opacity: 1;
            }
          }
          .editName {
            transition: all 0.3s;
            opacity: 0;
            width: 18px;
            height: 17px;
            margin-left: 8px;
          }
        }
        .tool {
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .time {
            line-height: 14px;
            font-size: 14px;
            color: #979797;
          }
          .btns {
            display: flex;
            align-items: center;
            img {
              margin-right: 10px;
              &:last-child {
                margin-right: 0;
              }
              &.download {
                width: 19px;
                height: 17px;
              }
              &.preview {
                width: 22px;
                height: 13px;
              }
              &.edit {
                width: 18px;
                height: 17px;
              }
              &.delete {
                width: 13px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
  .empty {
    box-sizing: border-box;
    // background-color: #fff;
    text-align: center;
    height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 150px;
      margin-bottom: 15px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: #909399;
      a {
        cursor: pointer;
      }
    }
  }
}
</style>
