<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2022-02-14 10:21:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-03-24 08:47:44
-->
<template>
  <el-dialog custom-class="previewDialog" :visible.sync="dialogVisible">
    <img :src="dataUrl" alt="" />
    <img src="@/assets/teacher/close_icon.png" class="close" @click="dialogVisible = false" />
    <el-button v-if="isDownload" type="primary" class="downloadBtn" @click="toDownload"
      >下载</el-button
    >
  </el-dialog>
</template>
<script>
export default {
  name: 'PosterPreview',
  props: {
    isDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      dataUrl: '',
    }
  },
  methods: {
    open(dataUrl) {
      this.dataUrl = dataUrl
      this.dialogVisible = true
    },
    toDownload() {
      try {
        let alink = document.createElement('a')
        alink.href = this.dataUrl
        alink.target = '_blank'
        alink.click()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .previewDialog {
  position: relative;
  width: calc(100vw - 568px);
  height: calc(100vh - 140px);
  margin-left: 50%;
  margin-top: 70px !important;
  transform: translateX(calc(-50% + 100px));
  .close {
    cursor: pointer;
    position: absolute;
    right: 33px;
    top: 27px;
    width: 32px;
    height: 32px;
  }
  .downloadBtn {
    margin: 20px auto;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    box-sizing: border-box;
    text-align: center;
    padding: 80px;
    height: 100%;
  }
  img {
    display: block;
    height: 100%;
    margin: 0 auto;
  }
}
</style>
